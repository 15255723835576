@import '~bootstrap/scss/functions';
// Override default colors
$body-bg: #fff;

$primary: #003595;
$primary-dark: #00276f;
$secondary: #525252;
$gray-100: #f4f4f4;
$gray-200: #e0e0e0;
$gray-300: #c6c6c6;
$gray-400: #a8a8a8;
$gray-500: #8d8d8d;
$gray-600: #6f6f6f;
$gray-700: #525252;
$gray-800: #393939;
$gray-900: #262626;

$light: #fafafa;
$dark: #262626;
$white: #fff;

$font-family-base:
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';

$enable-negative-margins: true;

$btn-font-weight: 500;

$card-bg: $white;
$card-box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
$card-border-width: 0;
$progress-border-radius: 2px;

$modal-md: 540px;
$modal-content-color: $gray-900;

// Configuration
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

// Custom Colors
$myo-aqua: #36cbd9;
$myo-aqua-rgb: 53, 203, 217;
$myo-cyan: #36cbd9; // TODO: replace all "cyan" and "myo-cyan" with "aqua" and "myo-aqua"
$myo-blue: #2d9bf0;
$myo-blue-rgb: 45, 155, 240;
$myo-blue-subtle: tint-color($myo-blue, 80%);
$myo-blue-light: #f0f8fe;
$myo-teal: #0094aa;
$myo-teal-rgb: 0, 148, 170;
$myo-teal-subtle: tint-color($myo-teal, 80%);
$myo-teal-light: #f6fcfe;
$myo-success: #198754;
$myo-subtle: #ccd7ea;
$myo-subtle2: #b8c6e1;

$accents: (
  '100': $myo-cyan,
  '200': $myo-blue,
  '300': $myo-blue-light,
  '400': $myo-teal,
  '500': $myo-teal-light,
);
$text-secondary: rgba($gray-900, 0.75);
$bg-tertiary: $gray-100;

// Custom Font Sizes
$font-size-xs: $font-size-base * 0.75; // 0.75rem = 12px
$font-size-2xs: $font-size-base * 0.625; // 0.625rem = 10px

// Offset variables
$header-height-offset: 76px;
$header-height-offset-mobile: 60px;
$access-banner-height: 40px;
$access-banner-height-mobile: 16px;

// Layout variables (use for padding or margin)
$layout-all-space-top: $spacer * 1.5;
$layout-single-col-space-lgUp-bottom: $spacer * 5;
$layout-single-col-space-lgDown-bottom: $spacer * 3.5;
