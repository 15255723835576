
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.pgx-cta-wrapper {
  align-items: center;
}

.pgx-cta-wrapper,
.pgx-filter-wrapper {
  border: 1px solid $myo-aqua;
  border-radius: $border-radius-sm;
  background-color: $myo-teal-light;
}

// NOTE: badges use em units so that they scale with font-size of parent elements
.guidance-badge.badge {
  --bs-badge-padding-x: 0.35em;
  --bs-badge-padding-y: 0.3em;
  --bs-badge-font-size: 0.5625em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0;
  display: block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  text-transform: uppercase;
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.guidance-badge-row {
  display: flex;
  align-items: center;
  padding-left: $spacer * 0.5;
  & > .badge + .badge {
    margin-left: $spacer * 0.25;
  }
}

.guidance-btn {
  text-decoration: none;
  font-weight: 500;
  width: 100%;
}

.guidance-table-wrapper {
  max-height: 400px;
  overflow-y: auto;
  // adding border top and bottom replaces the borders that are removed
  // to make the sticky header scroll more elegant for table with .table-border class
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  @include media-breakpoint-down(md) {
    max-height: 300px;
  }
}
.guidance-table {
  .spanned-row > th {
    font-size: $font-size-sm;
    background-color: $gray-100;
    padding: {
      top: 1px !important;
      bottom: 1px !important;
    }
  }
  td,
  th[scope='row'] {
    vertical-align: middle !important;
  }
  th[scope='row'] {
    font-weight: 400;
  }
  thead,
  tbody,
  tfoot {
    position: relative;
  }
  thead {
    th {
      position: sticky;
      background-repeat: no-repeat;
      background-position:
        0 0,
        0 0;
      background-size:
        100% calc(100% - 1px),
        100% 100%;
      z-index: 1;
    }
    tr {
      // since we have more than one row in sticky header, we must remove top and bottom borders
      // otherwise they misalign when scrolling
      // background-image gradient on table head cells simulates a border on the bottom of each cell
      border-top: none;
      border-bottom: none;
      &:nth-of-type(1) {
        th {
          top: -1px;
          &:not(.th-guidance) {
            height: 32px;
            background-image: linear-gradient($gray-200, $gray-200), linear-gradient($border-color, $border-color);
          }
          &.th-guidance {
            background-image: linear-gradient($gray-100, $gray-100), linear-gradient($border-color, $border-color);
          }
        }
      }
      &:nth-of-type(2) {
        th {
          top: 31px; // this value and height of the cell in row above it must match
          background-image: linear-gradient($gray-100, $gray-100), linear-gradient($border-color, $border-color);
        }
      }
    }
  }
  tbody {
    tr:nth-of-type(1) {
      // we remove border-top from the first row of the table body so that it does not appear
      // to have a thick border between thead and tbody prior to scroll
      border-top: none;
    }
    tr:last-of-type {
      // we remove border-bottom from the last row of the table body so that it does not appear
      // to have a thick border at bottom of the table when at bottom of scroll
      border-bottom: none;
    }
    // fixes issue where the hidden drug trade names were adding height to the page even though
    // neither they nor the table rows were visible
    td span.sr-only {
      height: 0;
    }
  }
  &.table {
    margin-bottom: 0;
  }
}

.th-guidance {
  background-color: $gray-100 !important;
}
.td-guidance,
.th-guidance {
  text-align: center;
}

span.trade-name {
  &:not(:only-of-type):not(:last-of-type) {
    &:after {
      content: ', ';
    }
  }
}
li.trade-name {
  &:not(:only-of-type):not(:last-of-type) {
    span:after {
      content: ', ';
    }
  }
}

.annotation-accordion {
  --bs-accordion-btn-padding-y: 0.25rem !important;
  --bs-accordion-btn-padding-x: 0.5rem !important;
  --bs-accordion-body-padding-y: 0.5rem !important;
  --bs-accordion-body-padding-x: 0.5rem !important;
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-inner-border-radius: 0 !important;
  --bs-accordion-active-bg: $body-bg !important;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  .accordion-item {
    border-color: $gray-200;
    &:has(.accordion-button.collapsed:hover) {
      background-color: $light;
    }
    &:has(.accordion-button:not(.collapsed)) {
      padding-bottom: $spacer * 0.5; // adds space below content when item is expanded
    }
    & + .accordion-item {
      margin-top: $spacer * 0.5;
    }
  }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
    & > .accordion-header .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius);
    }
  }
  .accordion-item:last-of-type {
    border-bottom-left-radius: var(--bs-accordion-border-radius);
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    & > .accordion-header .accordion-button {
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    }
  }
  .accordion-item:not(:first-of-type) {
    border-top: var(--bs-accordion-border-width) solid $gray-200;
  }
  .accordion-button {
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    &.collapsed {
      background-color: transparent;
    }
    &:not(.collapsed) {
      color: $primary;
      background-color: var(--bs-accordion-active-bg);
      box-shadow: none;
    }
  }
  .accordion-body {
    padding-top: var(--bs-accordion-body-padding-y);
    padding-bottom: var(--bs-accordion-body-padding-y);
    padding-left: var(--bs-accordion-body-padding-x);
    padding-right: calc(var(--bs-accordion-body-padding-x) * 2);
    margin-left: var(--bs-accordion-btn-padding-x); // indent body to match button padding
    border-left: 4px solid $gray-200;
  }
}

.annotation-genotype-list {
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(lg) {
    flex-wrap: wrap;
    li + li {
      margin-left: $spacer * 0.5;
    }
  }
  @include media-breakpoint-down(lg) {
    // to prevent long alleles from overflowing modal on mini-tablet and mobile
    flex-direction: column;
    align-items: flex-start;
    li {
      text-align: left;
      span {
        white-space: normal;
      }
      & + li {
        margin-top: $spacer * 0.5;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    li {
      max-width: 30ch;
    }
  }
}

.annotation-inner-html {
  padding-left: $spacer * 0.5;
  padding-right: $spacer;
  h1,
  h2,
  h3 {
    font-size: $h5-font-size;
    font-weight: $font-weight-medium;
  }
  h4,
  h5,
  h6 {
    color: var(--bs-emphasis-color);
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    line-height: $line-height-base;
    margin-bottom: 0;
  }
  p,
  ul,
  ol {
    font-size: $font-size-sm;
    &:not(:last-child) {
      margin-bottom: $spacer * 0.5;
    }
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

.annotation-single-pop {
  list-style-type: none;
  padding-left: 0;
  padding-bottom: $spacer * 0.5;
  border: 1px solid $gray-200;
  li {
    border: none;
    .single-pop-header {
      padding: calc($spacer * 0.25) calc($spacer * 0.5); // match accordion button padding
    }
    .single-pop-body {
      // match accordion body padding
      padding: calc($spacer * 0.5) $spacer calc($spacer * 0.5) calc($spacer * 0.5);
      margin-left: $spacer * 0.5;
      border-left: 4px solid $gray-200;
    }
  }
}

.accordion-body,
.single-pop-body {
  @include media-breakpoint-down(lg) {
    p:has(+ ul.annotation-genotype-list) {
      align-self: flex-start;
    }
  }
}

.source-list {
  list-style: none;
  li + li {
    margin-top: $spacer * 0.5;
  }
}

.tag-list {
  list-style: none;
  .badge {
    width: 100px;
  }
  li + li {
    margin-top: $spacer;
  }
  p strong {
    font-weight: $font-weight-medium;
  }
}
