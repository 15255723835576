
          @import "./src/stylesheets/fonts.scss";
          @import "./src/stylesheets/theme.scss";
          @import "./src/stylesheets/common.scss";
          @import "./src/stylesheets/print.scss";
        
.requisition-complete-container {
  section {
    .fa-circle-check {
      color: $success;
      margin: 28px 0 16px;
    }

    .requisition-receipt-content-wrapper {
      max-width: 540px;
      width: 100%;
    }

    .requisition-complete-actions {
      @include media-breakpoint-down(md) {
        & > button {
          width: 194px;
          justify-content: center;
        }
      }
    }
  }
}
