@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a {
    &:not(.btn) {
      text-decoration: underline;
    }
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #8d8d8d;
    page-break-inside: avoid; // fallback
    break-inside: avoid;
  }

  //
  // Printing Tables:
  // https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
  //

  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid; // fallback
    break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid; // fallback
    break-after: avoid;
  }

  // Specify a size and min-width to make printing closer across browsers.
  // We don't set margin here because it breaks `size` in Chrome. We also
  // don't use `!important` on `size` as it breaks in Chrome.
  @page {
    size: letter; // may need to switch between letter and a3
  }
  body {
    min-width: 992px !important;
    background-color: #fff;
  }
  header + div[class*='container'] {
    margin-top: 0 !important; // resolves extra space at top when navbar is postion relative
  }
  footer {
    display: none;
  }
  .container {
    min-width: 992px !important;
  }

  // Bootstrap components
  .navbar {
    box-shadow: none !important;
    border-color: #fff !important;
    position: relative; // resolves logo appearing on each page
    .navbar-nav,
    .navbar-toggler {
      display: none;
    }
  }
  .badge {
    border: 1px solid #000;
  }

  .breadcrumb {
    display: none;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid #c6c6c6 !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #c6c6c6;
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #c6c6c6;
  }

  // custom styles found in app

  .App {
    background-color: #fff;
  }

  .order-form-container {
    padding-top: 0.5rem !important;
  }

  .requisition-receipt-content > li.list-group-item {
    border-color: #8d8d8d;
    background-color: transparent;
  }

  .order-status.card {
    width: 240px; // so text inside doesn't wrap
  }

  .step-form__content {
    & > .property-wrapper:not(.d-none) {
      display: block;
    }
  }
}
